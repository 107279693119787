import React from 'react';

import {
  Typography,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled
} from '@mui/material'
import * as variable from "../../CSS Variable/Variable"


const FilterSidebar = () => {

  const Root = styled('div')(({ theme }) => ({
    backgroundColor: '#F7F7F7',
    padding: theme.spacing(2)
  }));
  
  const Header = styled(Typography)(({ theme }) => ({
    background: `${variable.Primary}`,
    color: '#fff',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }));
  
  const Subheading = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(2)
  }));

  const RadioBtn = styled(Radio)`
  padding : 6px
  `

  return (
    <Root>
      <Header variant="h6">
       Select Filters
      </Header>
      <Subheading>
        <Typography variant="subtitle1" gutterBottom>Cab Type</Typography>
        <FormControl component="fieldset">
          <RadioGroup aria-label="cab-type" name="cab-type">
            <FormControlLabel value="mini" control={<RadioBtn />} label="Mini" />
            <FormControlLabel value="sedan" control={<RadioBtn />} label="Sedan" />
            <FormControlLabel value="suv" control={<RadioBtn />} label="SUV" />
          </RadioGroup>
        </FormControl>
      </Subheading>
      <Divider />
      <Subheading>
        <Typography variant="subtitle1" gutterBottom>Fuel type</Typography>
        <FormControl component="fieldset">
          <RadioGroup aria-label="cab-type" name="cab-type">
            <FormControlLabel value="mini" control={<RadioBtn />} label="Diesel" />
            <FormControlLabel value="sedan" control={<RadioBtn />} label="Petrol" />
            <FormControlLabel value="suv" control={<RadioBtn />} label="Electric" />
          </RadioGroup>
        </FormControl>
      </Subheading>
      <Divider />
      <Subheading>
        <Typography variant="subtitle1" gutterBottom>Cab Model</Typography>
        <FormControl component="fieldset">
          <RadioGroup aria-label="cab-type" name="cab-type">
            <FormControlLabel value="mini" control={<RadioBtn />} label="Toyota Innova" />
            <FormControlLabel value="sedan" control={<RadioBtn />} label="Sedan" />
            <FormControlLabel value="suv" control={<RadioBtn />} label="SUV" />
          </RadioGroup>
        </FormControl>
      </Subheading>
      <Divider />
    </Root>
  )
}
export default FilterSidebar