import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TaxiImg from "../../asset/Images/Taxi.png"
import { Box, styled } from '@mui/material'



const AboutUs = () => {

    const MediaBox = styled(Box)`
    width: 40%;
    border : solid 5px blue;
    overflow : visible;
       &:hover{
        position : relative;
       }

       @media (max-width: 600px) {
        width: 100%;
        border: none;
        &:hover {
            position: static;
        }
    }
    `

    const CardImg = styled(CardMedia)`
    minWidth: 200;
    top : 0;
    left : 0;
     object-fit: "cover"; 
     height : "590px";
     transition : all 0.5s;
       &:hover{
        position : relative;
        left : -20px;
        top : 20px;
         }

       @media (max-width: 600px) {
        height: 250px;
    }
    `
    const AboutCard = styled(Card)`
display: flex; 
height: auto;
overflow : visible;
@media (max-width: 600px) {
    flex-direction : column; }
`
    return (
        <AboutCard sx={{}} >
            <MediaBox>
                <CardImg
                    component="img"
                    sx={{ minWidth: 200, objectFit: "cover", height: "590px" }}
                    image={TaxiImg}
                    alt="Taxi Img"
                />
            </MediaBox>

            <CardContent sx={{ flex: 1, padding: "50px", boxShadow: "none" , "@media (max-width: 600px)" : { padding : "10px" } }}>
                <Typography variant="h5" sx={{ fontWeight: "700" }} gutterBottom>
                    About Us
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: "700" }} gutterBottom>
                    No. 1 TAXI BOOKING IN DEHRADUN
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "600", fontSize: "1.1rem" }} gutterBottom>
                    Taxi refers to for-hire automobile travel supplied by private companies. Taxi service is an important Transportation Option that meets a variety of needs, including Basic Mobility in emergencies, general transportation for non-drivers, and mobility for Tourists and visitors.
                </Typography>
            </CardContent>
        </AboutCard>
    );
};

export default AboutUs;
