import React from 'react';
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, 
    TableRow, Typography, styled, List, ListItem } from '@mui/material';

function Description() {

    const Bullets = styled(List)({
        paddingLeft: "10px",
        fontWeight: "500"
    });

    const BulletItem = styled(ListItem)({
        padding: "4px"
    })

    const MainHeading = styled(Typography)`
    font-weight : 600;
    `
    const Container = styled(Typography)({
        background: "#F7F7F7",
        borderRadius: '5px',
        // boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
        marginBottom : "15px;",
    })
    const DescriptionPage = styled(Box)({

    })

    const DetailsBox = styled(Box)({
        margin: "20px 0",
        padding: "16px"
    })


    const StyledColumn = styled(Box)`
  display: flex;
  flex-direction: column;
`;

    const StyledHeader = styled(Typography)`
  font-weight: bold;
`;

    const StyledSubHeader = styled(Typography)`
  font-weight: bold;
  text-decoration: underline;
  margin-top: 8px;
`;


    return (
        <DescriptionPage>
            <DetailsBox>
                <MainHeading variant="h6" >Driver and Cab Details</MainHeading>
                <Typography>Cab and Driver Details will be shared upto 30 mins prior to departure. </Typography>
            </DetailsBox>

            <Container sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <MainHeading variant="h6" gutterBottom>Inclusion (Included in Price)</MainHeading>
                        <Bullets>
                            <BulletItem>Night Charges (10 PM to 6 AM)</BulletItem>
                            <BulletItem>State Tax</BulletItem>
                            <BulletItem>Toll Charges</BulletItem>
                            <BulletItem>Driver Allowances</BulletItem>
                            <BulletItem>One Pickup and Drop</BulletItem>
                        </Bullets>
                    </Grid>
                    <Grid item xs={6} sx={{ borderLeft: "solid 1px grey" }}>
                        <MainHeading variant="h6" gutterBottom>Exclusion (Extra Charges)</MainHeading>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Waiting Charges</TableCell>
                                        <TableCell>Fare Beyond 262 Km</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>After 45 minutes: Rs. 300/hr</TableCell>
                                        <TableCell>Rs. 16/km</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ p: 2 }}>
            <Grid container spacing={2}>
           
                <Grid item xs={6}>
                <StyledHeader>Read Before You Book!</StyledHeader>
                    <StyledColumn>
                        <Box>
                            <StyledSubHeader>Cab Category</StyledSubHeader>
                            <Typography>Choose from our range of cab categories: economy, business, or luxury.</Typography>
                        </Box>
                        <Box>
                            <StyledSubHeader>Hilly Region</StyledSubHeader>
                            <Typography>If you are traveling to a hilly region, please inform us in advance so that we can assign a suitable driver and vehicle.</Typography>
                        </Box>
                        <Box>
                            <StyledSubHeader>Language Policy</StyledSubHeader>
                            <Typography>We have drivers who can communicate in multiple languages. Please let us know your language preference at the time of booking.</Typography>
                        </Box>
                        <Box>
                            <StyledSubHeader>Driver Details</StyledSubHeader>
                            <Typography>We ensure that all our drivers are licensed, experienced, and trained to provide excellent service to our customers.</Typography>
                        </Box>
                    </StyledColumn>
                </Grid>
                  

                <Grid item xs={6}>
                {/* <StyledHeader>Additional Charges</StyledHeader> */}
                    <StyledColumn>         
                        <Box>
                            <StyledSubHeader>Stops</StyledSubHeader>
                            <Typography>If you need to make additional stops during your journey, please inform your driver in advance. Additional charges may apply.</Typography>
                        </Box>
                        <Box>
                            <StyledSubHeader>Waiting Charges</StyledSubHeader>
                            <Typography>If you need to make a stop and wait for a period of time, waiting charges will be applied after the first 15 minutes.</Typography>
                        </Box>
                        <Box>
                            <StyledSubHeader>Delays</StyledSubHeader>
                            <Typography>If your journey is delayed due to traffic or other unforeseen circumstances, additional charges may apply if your booking goes beyond the allotted time slot.</Typography>
                        </Box>
                        <Box>
                            <StyledSubHeader>Receipts</StyledSubHeader>
                            <Typography>We provide detailed receipts for all our bookings. If you require a receipt, please ask your driver at the end of the journey.</Typography>
                        </Box>
                    </StyledColumn>
                </Grid>
                </Grid>
            </Container>
        </DescriptionPage >
    );
};

export default Description