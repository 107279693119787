import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

const CardWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "1rem",
  marginBottom: "2rem",
});

const ReviewWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const TestimonialCard = ({ image, name, review }) => {
  return (
    <CardWrapper>
      <img src={image} alt={name} width="200px" height="200px" />
      <ReviewWrapper>
        <Typography variant="h5">{name}</Typography>
        <Typography variant="subtitle1" sx={{ fontStyle: "italic" }}>
          {review}
        </Typography>
      </ReviewWrapper>
    </CardWrapper>
  );
};

export default TestimonialCard;
