import { useState } from 'react';
import styled from 'styled-components';
import { Button, FormControl, InputLabel, MenuItem, Select, Box } from '@mui/material';
import * as variable from "../../CSS Variable/Variable"
import OneWay from "../Search/OneWay"
import RoundTrip from '../Search/RoundTrip';
import Local from '../Search/Local';
import { useRef } from 'react';

const SubHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #f7f7f7;
  gap : 1vw;
  @media (max-width: 600px) {
      display : none;
      }
`;

const SubHeaderFormControl = styled(FormControl)`
  margin-right: 2rem;
`;


const SearchButton = styled(Button)`
    background : ${variable.Primary};
    color : white;
    border-radius : 25px;
    height : 12%;
    min-width : 10%;
    font-size: 1.3rem;
    font-weight : 600;
    `
const ComponentContainer = styled(Box)`
    width : 65vw
    `

function SearchHeader() {
  const [bookingType, setBookingType] = useState('OneWay');
  const childRef = useRef();

  const handleBookingTypeChange = (event) => {
    setBookingType(event.target.value);
  };

  const handleSearch = () => {
    console.log(childRef)
    childRef.current.handleSearch()
  };

  return (
    <SubHeaderContainer>
      <SubHeaderFormControl>
        {/* <InputLabel>Booking Type</InputLabel> */}
        <Select value={bookingType} onChange={handleBookingTypeChange} sx={{ background: `${variable.Primary}`, color: "white", minWidth: "8vw" }}>
          <MenuItem value="OneWay">One Way</MenuItem>
          <MenuItem value="Round">Round</MenuItem>
          <MenuItem value="Local">Local</MenuItem>
        </Select>
      </SubHeaderFormControl>
      <ComponentContainer>
        {bookingType === 'OneWay' && <OneWay ref={childRef} BtnHide={true} width="70vw" />}
        {bookingType === 'Round' && <RoundTrip ref={childRef} BtnHide={true} />}
        {bookingType === 'Local' && <Local ref={childRef} BtnHide={true} width="79vw" />}
      </ComponentContainer>
      <SearchButton variant="contained" color="primary" onClick={handleSearch}>
        Search
      </SearchButton>
    </SubHeaderContainer>

  )
}

export default SearchHeader