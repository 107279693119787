import { useState } from "react";
import Slider from "react-slick";
import TestimonialCard from "./TestimonialCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from "@mui/material";

const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <IconButton
      className={className}
      onClick={onClick}
      sx={{
        position: "absolute",
        zIndex: 1,
        left : "-9%",
        top: "calc(50% - 24px)",
        width: "80px",
        height: "80px",
        borderRadius: "50%",
        color : "grey",
        "&:hover": {
            color : "white",
          background: "grey",
        },
      }}
    >
            <ArrowBackIosIcon disable  sx={{fontSize : "4rem"}} />
        </IconButton>
    );
};

const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <IconButton
        className={className}
        onClick={onClick}
        sx={{
          position: "absolute",
          zIndex: 1,
          left: "101%",
          top: "calc(50% - 24px)",
          width: "80px",
          height: "80px",
          borderRadius: "50%",
          color : "grey",
          "&:hover": {
            color : "white",
          background: "grey",
          },
        }}
      >
            <ArrowForwardIosIcon sx={{fontSize : "4rem"}} />
        </IconButton>
    );
};



const TestimonialSlider = ({ testimonials }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setCurrentSlide(next),
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
    };

    return (
        <>
            <Slider {...settings}>
                {testimonials.map((testimonial) => (
                    <TestimonialCard key={testimonial.id} {...testimonial} />
                ))}
            </Slider>
        </>
    );
};

export default TestimonialSlider;
