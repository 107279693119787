import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Checkbox, FormGroup } from '@mui/material';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as variable from "../../CSS Variable/Variable"
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

function BookNow({ Price, BtnName, formDetails }) {
    const navigate = useNavigate()
    const [paymentType, setPaymentType] = useState('payNow');

    const SquareBox = styled(FormControlLabel)`
`;

    const Label = styled('span')`
        font-size: 0.8rem;
        font-weight : 600;
        
    `;

    const BookNowButton = styled(Button)`
        margin-top : 10vh;
    `;

    const handlePaymentTypeChange = (event) => {
        setPaymentType(event.target.value);
    };

    const handleBook = async () => {
        if (BtnName === "Book Now") {
            navigate("/Booking")
        }
        else if (BtnName === "Confirm Booking") {
            if ('pickupAddress' in formDetails && 'travellerName' in formDetails && 'gender' in formDetails && 'emailId' in formDetails && 'contactNumber' in formDetails )
             {
                const requestOptions = { 
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formDetails),
                    redirect: 'follow'
                };
    
                try {
                    const response = await fetch("https://taxi-api.leetwolf.in/trip/post", requestOptions);
                } catch (error) {
                    console.log('error', error);
                }
               navigate("/BookingSuccess")
            }
            else{
                alert("Fields except Drop Off Address are compulsory to filled.")
            }
            }
          


    }


    return (
        <Box sx={{ padding: '1rem' }}>
            <Typography variant="h5" sx={{}}>
                Total Amount
            </Typography>
            <Typography variant="h3" sx={{}}>
                Rs. {Price}
            </Typography>
            <Typography variant="body2" color="darkblue" sx={{ marginBottom: '1rem', fontSize: "0.7rem" }}>
                (Incl. of Tolls and Taxes)
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '5rem',
                }}
            >
                {
                    BtnName === "Book Now" ?
                        <FormGroup>
                            <SquareBox control={<Checkbox />} label={<Label>Pay Now</Label>} />
                            <SquareBox control={<Checkbox />} label={<Label>Make Part Payment Now</Label>} />
                        </FormGroup> : <Typography>By clicking on CONFIRM BOOKING , you agree to our <Link to="/TermsAndConditions">Terms And Conditions</Link></Typography>}

            </Box>

            <BookNowButton variant="contained" size="large" onClick={handleBook} sx={{ width: '100%', background: `${variable.Primary};` }}>{BtnName}</BookNowButton>
        </Box>
    );
};
export default BookNow