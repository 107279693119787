import React,{useState} from 'react'
import CabContext from './CabContext'

function Contxt(props) { 
    const [bookingAmount , setBookingAmount] = useState(0)
    const [cabType , setCabType] = useState("Hatchback")
    const [img , setImg] = useState("hatchback")
   
    return (
    <CabContext.Provider value={{ bookingAmount , setBookingAmount , cabType , setCabType , img , setImg  }}>
        {props.children}
        </CabContext.Provider>
  )
}

export default Contxt