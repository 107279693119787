import { SEARCH_FOR_CAB_REQUEST, SEARCH_FOR_CAB_SUCCESS, SEARCH_FOR_CAB_FAIL } from "../Constants/CabConstants";
const hostName = "https://taxi-api.leetwolf.in"
// const hostName = "http://localhost:1000"

export const getCabSearchDetails = (query) => async (dispatch) => {
    try {
        dispatch({ type: SEARCH_FOR_CAB_REQUEST })
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        const response = await fetch(`${hostName}/destination/search?from=${query.from}&to=${query.to}&tripType=${query.tripType}&pickupDate=${query.pickupDate}&returnDate=${query.returnDate}`, requestOptions);
        const cabSearchResults = await response.json();
        const result = { userQuery: query, searchedResult: cabSearchResults }

        dispatch({
            type: SEARCH_FOR_CAB_SUCCESS,
            payload: result
        })

    }
    catch (error) {
        dispatch({
            type: SEARCH_FOR_CAB_FAIL,
            payload: error.response.data.message,
        })
    }
}