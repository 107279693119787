import React from 'react'
import styled from 'styled-components';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`;

const TextContainer = styled.div`
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%;
`;

const BackButton = styled(Button)`
  margin-top: 20px !important;
`;

function TermsAndCondition() {
  return (
   <> 
   <Container>
   <TextContainer>
     <Typography variant="h6" gutterBottom>
       Terms and Conditions for Cab Booking
     </Typography>
     <Typography variant="body1" gutterBottom>
       By using our Cab Booking services, you agree to the following terms and conditions:
     </Typography>
     <Typography variant="body1" gutterBottom>
       - We are not responsible for any damages or losses that may occur during your trip.
     </Typography>
     <Typography variant="body1" gutterBottom>
       - You must have a valid driver's license and insurance to operate our vehicles.
     </Typography>
     <Typography variant="body1" gutterBottom>
       - You are responsible for obeying all traffic laws and regulations.
     </Typography>
     <Typography variant="body1" gutterBottom>
       - You agree to pay for any damages or cleaning fees incurred during your trip.
     </Typography>
   </TextContainer>
   <Link to="/Booking">
     <BackButton variant="contained" color="primary">Back to Booking</BackButton>
   </Link>
 </Container></>
  )
}

export default TermsAndCondition