import React, { useContext } from 'react'
import FiltersSidebar from "./FiltersSidebar"
import Header from '../Header/Header'
import { Box, Button, Toolbar, styled } from '@mui/material'
import RoundTrip from '../Search/RoundTrip'
import * as variable from "../../CSS Variable/Variable"
import ResultsCard from './ResultsCard'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Footer from "../Footer/Footer"
import NoCabsAvailable from './NoCabAvialable'
import SearchHeader from '../SerachHeader/SearchHeader'
import Loader from '../Loader/Loader'

const FilterBox = styled(Box)`
width : 20%;
position : sticky;
@media (max-width: 600px) {
    display : none;
    }
`
const PageContainer = styled(Box)`
margin: auto;
margin-top : 5vh;
width: 84vw;
max-width: 1500px;
min-width: 1150px;
@media (max-width: 480px) {
    max-width: 480px;
    min-width: 100vw;
    margin-top : 2vh;
    }
`

const SearchContainer = styled(Box)`
display : flex;
align-items : center;
width: 100%;
margin: auto;
background : #F7F7F7;
margin-bottom : 10px;
@media (max-width: 600px) {
    display : none;
    }
`
const ResultsContainer = styled(Box)`
display : flex;
gap : 1%;
`

function SearchResult() {
    const navigate = useNavigate();
    const { loading, searchDetails } = useSelector((state) => state.searchCab)

   
    return (
        <>
            <Header />
            {loading && <PageContainer><Loader /></PageContainer>}

            {!loading && searchDetails.searchedResult.success === true ? <PageContainer>
                <SearchContainer>
                    <SearchHeader />
                </SearchContainer>

                <ResultsContainer>
                    <FilterBox>
                        <FiltersSidebar />
                        <Toolbar />
                    </FilterBox>

                    <Box sx={{ width: "100%" }}>
                        {/* { searchDetails.map((cab , key)=>{ <ResultsCard btnShow={true} Details = {cab} />})} */}
                        <ResultsCard btnShow={true} img="hatchback" CabType="Hatchback (Indica/WagonR)" cabPrice={searchDetails.searchedResult.data.miniPrice} />
                        <ResultsCard btnShow={true} img="sedan" CabType="Sedan(Dzire/Etios)" cabPrice={searchDetails.searchedResult.data.sedanPrice} />
                        <ResultsCard btnShow={true} img="suv" CabType="Mini-SUV" cabPrice={searchDetails.searchedResult.data.suvPrice} />
                        <ResultsCard btnShow={true} img="innova" CabType="Toyota Innova(XUV)" cabPrice={searchDetails.searchedResult.data.toyotaInnovaXUVPrice} />
                        <ResultsCard btnShow={true} img="crysta" CabType="Toyota Innova Crysta" cabPrice={searchDetails.searchedResult.data.toyotaInnovaCrystaPrice} />
                        <ResultsCard btnShow={true} img="traveller1" CabType="Tempo Traveller(12+1)" cabPrice={searchDetails.searchedResult.data.tempoTraveller13Price} />
                        <ResultsCard btnShow={true} img="traveller2" CabType="Tempo Traveller(16+1)" cabPrice={searchDetails.searchedResult.data.tempoTraveller17Price} />
                    </Box>

                </ResultsContainer>
            </PageContainer> :
                <PageContainer>
                    <SearchContainer>
                        <SearchHeader />
                    </SearchContainer>
                    <NoCabsAvailable />
                </PageContainer>
            }
            <Footer />
        </>
    )
}

export default SearchResult