import React from 'react';
import styled from '@mui/material/styles/styled';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import * as variable from "../../CSS Variable/Variable";
import { useNavigate } from 'react-router-dom';
import CabContext from "../../CabContext"
import { useContext } from 'react';



const CardContainer = styled(Card)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    border: `1px solid ${theme => theme.palette.grey[300]}`,
    background: "#F7F7F7",
    marginBottom: "15px",
    "@media (max-width: 600px)": {
        width: "95%",
        marginBottom: "2px"
    }
});

const Media = styled(CardMedia)({
    width: '200px',
    height: '200px',
    backgroundSize: "contain",
    borderRadius: theme => theme.shape.borderRadius,
    "@media (max-width: 600px)": {
        width: '70px',
        height: '70px'
    }
});

const Content = styled(CardContent)({
    flex: 1,
    padding: '0 16px',
});

const Title = styled(Typography)({
    marginBottom: '0.5rem',
});

const Bullets = styled(List)({
    margin: 0,
    padding: 0,
});

const BulletItem = styled(ListItem)({
    padding: '0',
    color: "grey",
    margin: "0",
    "@media (max-width: 600px)": {
        fontSize: "10px"
    }
});

const Price = styled(Typography)({
    marginBottom: '0.5rem',
});

const BookNowContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

});

const BookNowButton = styled(Button)({
    background: `${variable.Primary}`,
    color: "white",
    borderRadius: "15px",
    border: `solid 1px blue`,
    '&:hover': {
        background: "transparent",
        color: `blue`,

    },
});

const TCell = styled(TableCell)
    ({
        padding: "0.25rem",
        border: "none",
    })

const ResultsCard = ({ btnShow, CabType, cabPrice, img }) => {
    const navigate = useNavigate();
    const context = useContext(CabContext)
    const { setBookingAmount, setCabType, setImg } = context;


    const handleBook = (Price, cabType) => {
        setBookingAmount(Price)
        setCabType(cabType)
        setImg(img)
        navigate("/CabDescription")
    }


    return (
        <CardContainer>
            {
            img === "suv" ? 
            <Media image="https://th.bing.com/th/id/R.d2926402f3a83c723042f8917dafd29e?rik=1DLsq0bYtPiyRA&riu=http%3a%2f%2fcarleaseoffers-clo.com%2fwp-content%2fuploads%2f2018%2f02%2fCar-Lease-Offers-CLO-2016-kia-sorento-sx-suv-angular-front.png&ehk=69%2fxvMb1pjtp2fRdRKnl3C56OM6hoJzFO%2blT5wi4kQQ%3d&risl=&pid=ImgRaw&r=0"
                title="Product Image" /> :
               
              img === "hatchback" ?
                    <Media image="https://images.carandbike.com/car-images/colors/maruti-suzuki/swift/maruti-suzuki-swift-prime-lucent-orange.png?v="
                        title="Product Image" /> :

                    img === "innova" ?
                        <Media image="https://d3ggoe3aghc7um.cloudfront.net/uploads/prices/609/002_609_1618384352522_000.png"
                            title="Product Image" /> :

                        img === "crysta" ?
                            <Media image="https://th.bing.com/th/id/R.d5b60a61e1fdb0b9eb990d90f56178e0?rik=qAWDK8VmQHOdRQ&riu=http%3a%2f%2fwww.toyotakrungthai.com%2fwp-content%2fuploads%2f2018%2f11%2fInnova_Attitude-Black-Mica.png&ehk=1zMjg%2bFtyGGeOjnDjXs6WmJcW61Bh2Ebj6RF1Y5UCYk%3d&risl=&pid=ImgRaw&r=0"
                                title="Product Image" /> :
                         
                                img === "traveller1" ?
                                <Media image="https://th.bing.com/th/id/OIP.x47ArgwC_UlpA1HIVgSv8AHaFM?pid=ImgDet&rs=1"
                                    title="Product Image" /> :
                             
                                    img === "traveller2" ?
                                    <Media image="https://ik.imagekit.io/TrucksBuses/uploads/Force%20Tempo%20Traveller%20Royale(1).jpg"
                                        title="Product Image"/> :

                                    img === "sedan" ?
                                        <Media image="https://st.automobilemag.com/uploads/sites/10/2015/11/2015-toyota-corolla-s-at-sedan-angular-front.png"
                                            title="Product Image" />
                                        : 
                                        null
                                        }
            <Content>
                <Title variant="h5" sx={{ margin: "0" , fontWeight:700 }}>{CabType}</Title>
                <Bullets>
                    <BulletItem>
                        <ListItemText primary="AC" />
                    </BulletItem>
                    <BulletItem>
                        {img === "suv" ? <ListItemText primary="6-7 seats" /> : img === "hatchback" ? <ListItemText primary="4-5 seats" />
                            : img === "sedan" ? <ListItemText primary="4-5 seats" /> : img === "innova" ? <ListItemText primary="7 seats" />
                                : img === "crysta" ? <ListItemText primary="7 seats" /> : img === "traveller1" ? <ListItemText primary="13 seats" />
                                    : img === "traveller2" ? <ListItemText primary="17 seats" /> : null}
                    </BulletItem>
                </Bullets>
                {CabType === "SUV" ? <Typography variant='h6'  >Spaced Car</Typography> : <Typography variant='h6'>Compact Car</Typography>}
                <Table>
                    <TableBody>
                        <TableRow>
                            <TCell>Extra km fare</TCell>
                            <TCell>Rs.16/km after 200km</TCell>
                        </TableRow>
                        <TableRow>
                            <TCell>Fuel Type</TCell>
                            <TCell>Petrol</TCell>
                        </TableRow>
                        <TableRow>
                            <TCell>Cancellation</TCell>
                            <TCell>Free cancellation up to 24 hrs before pickup .</TCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Content>
            {btnShow ? <BookNowContainer>
                <Price variant="h4" sx={{ fontWeight: 700 }}> Rs.{cabPrice}</Price>
                <BookNowButton variant="contained" onClick={() => handleBook(cabPrice, CabType, img)}>  Book Now </BookNowButton>
            </BookNowContainer> : null}
        </CardContainer>
    )
}

export default ResultsCard;
