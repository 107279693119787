import { useEffect } from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const SuccessBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const SuccessText = styled.h5`
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
`;

const SuccessIcon = styled(CheckCircleIcon)`
  font-size: 3rem;
  color: green;
`;

const EmailText = styled.p`
  font-size: 1.2rem;
  margin-top: 1rem;
`;

function BokingConfirm() {
    const navigate = useNavigate()
   
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/');
        }, 2000);

        return () => clearTimeout(timer);
    }, [navigate]);
  
    return (
        <Container>
            <SuccessBox>
                <SuccessIcon />
                <SuccessText>Booking Request Sent Successfully</SuccessText>
            </SuccessBox>
            <EmailText>Please Check Your Email For Booking Details.</EmailText>
        </Container>
    );
}
export default BokingConfirm