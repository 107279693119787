import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as variable from "../../CSS Variable/Variable";

const FooterBox = styled(Box)({
  background : `${variable.Primary}`,
  color: '#FFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1rem',
  padding: '2rem',
  marginTop : "5vh",
  height : "40%",
  '@media(max-width: 768px)': {
    height: 'auto',
    padding: '2rem 1rem'
  }
});

const ColumnBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem'
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10%',
  width : "70%",
  '@media(max-width: 768px)': {
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    alignItems: "flex-start"
  }
});

const LogoBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '0.5rem',
  textAlign: 'center',
  width : "30%",
  '@media(max-width: 768px)': {
    width: '100%'
  }
});

const FlexBox = styled(Box)({
  display : "flex" , 
  gap : "20%" ,
  alignItems: "center",
  '@media(max-width: 768px)': {
    flexDirection : "column",
    width: '100%',
    gap : "5vh"
  }
})

const Footer = () => {
  return (
    <FooterBox>
      <FlexBox>
        <LogoBox>
          <Typography variant="h4" fontWeight="bold"> Logo</Typography>
          <Typography variant="body2">
            Taxi refers to for-hire automobile travel supplied by private companies. 
            Taxi service is an important Transportation Option that meets a variety of needs,
          </Typography>
        </LogoBox>
        <RowBox>
          <ColumnBox>
            <Typography variant="h6" fontWeight="bold">
              QUICK LINKS
            </Typography>
            <Typography variant="body2">Home</Typography>
            <Typography variant="body2">About Us</Typography>
            <Typography variant="body2">Services</Typography>
            <Typography variant="body2">Gallery</Typography>
            <Typography variant="body2">Contact Us</Typography>
          </ColumnBox>
          <ColumnBox>
            <Typography variant="h6" fontWeight="bold">
              SERVICES
            </Typography>
            <Typography variant="body2">Airport Transfer</Typography>
            <Typography variant="body2">Premium Taxi Transfer</Typography>
            <Typography variant="body2">Corporate Services</Typography>
          </ColumnBox>
          <ColumnBox>
            <Typography variant="h6" fontWeight="bold">
              CONTACT US
            </Typography>
            <Typography variant="body2">
              Address: Dehradun , Uttarakhand
            </Typography>
            <Typography variant="body2">Mobile: +91 9876543210</Typography>
            <Typography variant="body2">Email: taxibooking@mail.com</Typography>
          </ColumnBox>
        </RowBox>
      </FlexBox>
    </FooterBox>
  );
};

export default Footer;
