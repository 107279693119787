import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import icon1 from "../../asset/Images/Icon1.png";
import icon2 from "../../asset/Images/Icon2.png";
import icon3 from "../../asset/Images/Icon3.png";
import icon4 from "../../asset/Images/Icon4.png";

const FeatureBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem"
});

const FeatureIcon = styled("img")({
  width: "200px",
  height: "200px",
  borderRadius: "50%",
  border: "25px solid ",
  borderColor: `blue`,
  padding: "7px",
  boxSizing: "border-box",
});

const FlexBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  gap: "14%",
  "@media (max-width: 600px)":
  {
    flexDirection: "column"
  }
});


const Features = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "2rem" }}>
      <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: "7%" }}>
        WHAT SETS TAXI BOOKING?
      </Typography>
      <FlexBox>
        <FeatureBox>
          <FeatureIcon src={icon1} alt="icon1" />
          <Typography variant="h6">Clean and Hygienic Car</Typography>
        </FeatureBox>
        <FeatureBox>
          <FeatureIcon src={icon2} alt="icon2" />
          <Typography variant="h6">Transparent Billing</Typography>
        </FeatureBox>
        <FeatureBox>
          <FeatureIcon src={icon3} alt="icon3" />
          <Typography variant="h6">Expert Chauffeurs</Typography>
        </FeatureBox>
        <FeatureBox>
          <FeatureIcon src={icon4} alt="icon4" />
          <Typography variant="h6">2000+ cities</Typography>
        </FeatureBox>
      </FlexBox>
    </Box>
  );
};

export default Features;
