import styled from 'styled-components';

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background : #F7F7F7;
`;

const ErrorText = styled.h5`
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
`;

const ErrorSubText = styled.p`
  font-size: 1.2rem;
  margin-top: 1rem;
`;

function NoCabsAvailable() {
  return (
    <ErrorContainer>
      <ErrorText>OOPS! No Cabs Are Available For This Destination</ErrorText>
      <ErrorSubText>Please try a different destination.</ErrorSubText>
    </ErrorContainer>
  );
}

export default NoCabsAvailable
