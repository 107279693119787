import React, { useState } from 'react';
import { TextField, Box, styled, Autocomplete, IconButton, Button } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getCabSearchDetails } from '../../action/CabAction'
import * as variable from "../../CSS Variable/Variable"

const FormInput = styled(TextField)`
  border-radius: none;
  margin: 1%;
  background: #ffffff;
  border: solid 1px;
  border-color: blue;
`;

const HorizontalBox = styled(Box)({
  display: 'inline-block',
  verticalAlign: 'top',
});

const SwapIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: 0,
  fontSize: '2rem',
  backgroundColor: 'blue',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));


function Airport({ width }) {

  const [searchDetails, setSearchDetails] = useState({});
  const [options, setOptions] = useState([]);
  const [fromValue, setFromValue] = useState('FROM');
  const [toValue, setToValue] = useState('TO');
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const today = new Date();
  const todayDate = today.toISOString().slice(0, 10);
  const todayTime = today.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  
  const onChange = (e) => {
    const { name, value } = e.target;
    setSearchDetails({ ...searchDetails, [name]: value })
  };

  const handleSearch = () => {
    dispatch(getCabSearchDetails(searchDetails))
    navigate("/SearchedResult")
  }

  console.log(searchDetails);
  // console.log(toValue);
  // console.log(fromValue);


  const handleInputChange = async (event, value) => {
    const response = await fetch(
      `https://taxi-api.leetwolf.in/autocomplete?input=${value}`
    );
    const data = await response.json();
    if (Array.isArray(data)) {
      setOptions(data);
    }
    else {
      setOptions([])
    }
  };

  const isOptionEqualToValue = (option, value) =>
    option.description === value.description;

  const SearchButton = styled(Button)`
    background : ${variable.Primary};
    color : white;
    border-radius : 10px;
    height : 18%;
    min-width : 10%;
    font-size: 1.5rem;
    font-weight : 600;
    padding : 0 4% ;
    position: absolute;
    left: 43%;
    top: 90%;
 `


  return (
    <>
      <Box sx={{ width: width, display: "flex", gap: "3%" }}>
        <HorizontalBox sx={{ width: "25%" }}>
          <Autocomplete
            options={options}
            onInputChange={handleInputChange}
            getOptionLabel={(option) => option.description}
            isOptionEqualToValue={isOptionEqualToValue}
            name="from"
            onChange={(e, v) => {
              setFromValue(v.terms[0].value);
              setSearchDetails({ ...searchDetails, "from": v.terms[0].value })
            }}
            renderInput={(params) => (
              <FormInput
                {...params}
                label="Pickup Address"
                name="from"
                variant="outlined"
                // onChange={onChange}
                value={fromValue}
              />
            )}
          />
        </HorizontalBox>

        {/* <Box sx={{ position: 'absolute', width: '5%', left: "389px", top: "178px", zIndex: 5 }}>
          <SwapIconButton
            onClick={() => {
              setSearchDetails({ ...searchDetails, from: toValue, to: fromValue });
              setFromValue(toValue);
              setToValue(fromValue);
            }}

          >
            <SwapHorizIcon sx={{ color: "white" }} />
          </SwapIconButton>
        </Box> */}

        <HorizontalBox sx={{ width: "25%" }}>
          <Autocomplete
            options={options}
            onInputChange={handleInputChange}
            getOptionLabel={(option) => option.description}
            isOptionEqualToValue={isOptionEqualToValue}
            name="to"
            onChange={(e, v) => {
              setToValue(v.terms[0].value);
              setSearchDetails({ ...searchDetails, "to": v.terms[0].value })
            }}

            renderInput={(params) => (
              <FormInput
                {...params}
                label="Airport"
                variant="outlined"
                // onChange={onChange}
                value={toValue}
              />
            )}
          />
        </HorizontalBox>

        <HorizontalBox>
          <FormInput
            label="Departure"
            defaultValue={todayDate}
            type='date'
            name="pickupDate"
            variant="outlined"
            onChange={onChange}
          />
        </HorizontalBox>

        <HorizontalBox>
          <FormInput
            label="Pickup Time"
            defaultValue = {todayTime}
            type="time"
            name="pickupTime"
            variant="outlined"
            onChange={onChange}
          />
        </HorizontalBox>
      </Box>
      <SearchButton variant='contained' onClick={handleSearch}>Search</SearchButton>
    </>
  );
}

export default Airport;
