import { BrowserRouter as HashRouter, Routes, Route } from "react-router-dom";
import Homepage from "./Component/Homepage/Homepage";
import SearchResult from "./Component/SearchResult/SearchResult";
import BookingPage from "./Component/BookingPage/BookingPage";
import BookingForm from "./Component/Booking Form/BookingForm";
import BookingConfirm from "./Component/Booking Form/BookingConfirm";
import Context from "./Context";
import TermsAndCondition from "./Component/TermsAndConditions/TermsAndCondition";
import "./App.css"



function App() {
  return (
    <HashRouter>
      <Context>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/SearchedResult" element={<SearchResult />} />
          <Route path="/CabDescription" element={<BookingPage />} />
          <Route path="/Booking" element={<BookingForm />} />
          <Route path="/BookingSuccess" element={<BookingConfirm />} />
          <Route path="/TermsAndConditions" element={<TermsAndCondition/>} />
        </Routes>
      </Context>
    </HashRouter>
  );
}

export default App;
